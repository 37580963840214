<template>
  <div
    v-if="itemList.length"
    class="multi-text-image-banner"
    :class="[themeColorClass, imagePositionClass]"
  >
    <div v-if="bannerImage" class="multi-text-image-banner__image-wrapper">
      <UiImg
        :image="bannerImage"
        class="multi-text-image-banner__image"
        :sizes="{ xs: '512' }"
        use-image-size
      />
    </div>

    <div class="multi-text-image-banner__content-wrapper">
      <div
        v-for="item in itemList"
        :key="item.id"
        class="multi-text-image-banner__content"
      >
        <UiHeader
          v-if="item.name"
          class="multi-text-image-banner__title"
          :header="item.name"
          :header-layout="headerLayout"
          :header-position="headerPosition"
          :header-size="headerSize"
          :theme-color="themeColor"
        />

        <T3HtmlParser
          v-if="item.text"
          class="multi-text-image-banner__text"
          :content="item.text"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeProductBoxesProps } from '~/types'
import { UiHeader, UiImg } from '~ui/components'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)

const bannerImage = computed(() => props.itemList?.[0].image)

const imagePositionClass = computed(() => {
  return `multi-text-image-banner--image-${props.headerPosition}`
})
</script>

<style lang="scss">
.multi-text-image-banner {
  $base: &;

  display: grid;
  justify-content: space-between;
  gap: rem(48px);
  grid-template-columns: 1fr;
  grid-template-areas:
    'image'
    'content';

  @include media-query(md) {
    gap: rem(16px) rem(64px);
    grid-template-columns: 0.48fr 0.52fr;
    grid-template-areas: 'image content';

    &#{$base}--image-right {
      grid-template-columns: 0.52fr 0.48fr;
      grid-template-areas: 'content image';
    }
  }

  &__image {
    width: 100%;
    position: sticky;
    top: calc(rem(48px) + var(--content-offset, rem(0px)));

    &-wrapper {
      grid-area: image;
      position: relative;

      @include media-query(max-width md) {
        justify-content: center;
      }

      @include media-query(md) {
        #{$base}--image-right & {
          justify-content: flex-end;
        }
      }
    }
  }

  &__content-wrapper {
    grid-area: content;
  }

  &__image-wrapper + &__content-wrapper {
    @include media-query(max-width sm) {
      margin-top: rem(30px);
    }
  }

  &__content + &__content {
    margin-top: rem(100px);
  }

  &__title > * {
    margin: 0;
  }

  &__subtitle {
    color: color(gray);
    font-size: rem(20px);
    font-weight: 600;
    padding-top: em(30px);
  }

  &__text {
    color: color(gray);
    font-size: rem(18px);
    margin-top: rem(30px);

    @include themed-link;
  }
}
</style>
